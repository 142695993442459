import React, { useEffect, useState } from 'react';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import Header from '../../common/header/header';
import GlobalStyles from '../../styles/globalStyles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { I18nextProvider } from 'react-i18next';
import i18next from '../../i18next';
import Footer from '../../common/footer/footer';
import LocationDefault from '../../components/locationDefault/locationDefault';
import { Helmet } from 'react-helmet';

const LocationPageTemplate = ({ location }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  React.useEffect(() => {
    if (!location?.hash && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  if (typeof window === 'undefined') {
    return null;
  } else {
    return (
      <>
        <I18nextProvider i18n={i18next}>
          <GlobalStyles />
          <Header scrollPosition={scrollPosition} />
          <LocationDefault />
          <BackToTopScroll scrollPosition={scrollPosition} />
          <Footer />
        </I18nextProvider>
      </>
    );
  };
};

export default LocationPageTemplate;
